import { MediumTitle } from '@atb-ventures/personas-components';
import {
  afterArrow,
  beforeArrow,
  StyledLink,
} from 'containers/personas/navigation-tile/navigation-tile.style';
import styled, { css } from 'styled-components';
import { device } from 'theme';
import { NavigationTile } from '.';

export const PersonasNavigationTile = styled(NavigationTile)<{
  backArrow?: boolean;
}>`
  padding: 24px;
  width: auto;
  ${MediumTitle} {
    font-size: 20px;
  }
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: none;

  @media ${device.small} {
    margin-top: 30px;
    border: 1px solid #0072F0;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: none;
    ${MediumTitle} {
      color: #0072F0;
    }
    ${StyledLink} {
      display: none;
    }
  }

  ${(props) =>
    props.backArrow
      ? css`
          @media ${device.small} {
            ${MediumTitle} {
              position: relative;
              line-height: 25px;
              ${beforeArrow}
            }
          }
        `
      : css`
          @media ${device.small} {
            margin-top: 30px;
            border: 1px solid #0072F0;
            box-sizing: border-box;
            border-radius: 8px;
            box-shadow: none;
            ${MediumTitle} {
              color: #0072F0;
              line-height: 25px;
              position: relative;
              ${afterArrow}
            }
            ${StyledLink} {
              display: none;
            }
          }
        `};
`;
