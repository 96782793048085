import React from 'react';

export const ChartIcon = ({ stroke = '#0072F0' }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.36392 11.5694L15 1.66218L28.6361 11.5694L23.4276 27.5995H6.57244L1.36392 11.5694Z"
      stroke={stroke}
      strokeWidth="1.07143"
    />
    <path
      d="M6.26598 13.1619L15.0007 6.8158L23.7353 13.1619L20.399 23.4302H9.60233L6.26598 13.1619Z"
      stroke={stroke}
      strokeWidth="1.07143"
    />
    <path
      d="M11.208 14.7675L15.0014 12.0114L18.7949 14.7675L17.3459 19.2269H12.6569L11.208 14.7675Z"
      stroke={stroke}
      strokeWidth="1.07143"
    />
    <path
      d="M14.9986 2.07142L14.9977 16.0907"
      stroke={stroke}
      strokeWidth="1.07143"
    />
    <path
      d="M28.4075 12.0828L15.0005 16.1808"
      stroke={stroke}
      strokeWidth="1.07143"
    />
    <path d="M23.0359 27.25L15.0013 16" stroke={stroke} strokeWidth="1.07143" />
    <path d="M6.96368 27.25L15.0003 16" stroke={stroke} strokeWidth="1.07143" />
    <path
      d="M1.60901 11.7143L15.5409 16.1931"
      stroke={stroke}
      strokeWidth="1.07143"
    />
  </svg>
);
