import React, { FC, ReactComponentElement } from 'react';
import styled, { css } from 'styled-components';
import { TabOption } from 'utils/useTabs';
import { ChartIcon } from './chart-icon';
import { StoryIcon } from './story-icon';

const RadioButtonWrapper = styled.div`
  input[type='radio'] {
    position: absolute;
    visibility: hidden;
  }
`;

const RadioButtonLabel = styled.label<{ isSelected: boolean }>`
  line-height: 30px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #a7a9aa;
  color: #a7a9aa;

  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  margin: 8px;
  svg {
    padding-right: 8px;

    path {
      stroke: #a7a9aa;
    }
  }

  ${(props) =>
    props.isSelected &&
    css`
      color: #0072F0;
      border: 1px solid #0072F0;
      svg {
        path {
          stroke: #0072F0;
        }
      }
    `}
`;

const StoryChartTogglesContainer = styled.div`
  display: flex;
`;

interface ToggleButtonProps {
  icon: ReactComponentElement<any>;
  id: string;
  value: string;
  selectedValue: string;
  label: string;
  name: string;
  onChange: (e: any) => void;
}

const ToggleButton: FC<ToggleButtonProps> = ({
  icon,
  id,
  value,
  selectedValue,
  label,
  name,
  onChange,
}) => (
  <RadioButtonWrapper>
    <RadioButtonLabel
      htmlFor={id}
      className={'radioButtonLabel'}
      isSelected={value === selectedValue}
    >
      {!!icon && icon}
      {label}
    </RadioButtonLabel>
    <input
      type="radio"
      id={id}
      value={value}
      name={name}
      checked={value === selectedValue}
      onChange={onChange}
    />
  </RadioButtonWrapper>
);

interface StoryChartToggleProps {
  tabOptions: TabOption[];
  selectedOption: string;
  handleTabSelect: (e: any) => void;
  name: string;
}

const StoryChartToggle: FC<StoryChartToggleProps> = ({
  tabOptions,
  selectedOption,
  handleTabSelect,
  name,
}) => {
  return (
    <StoryChartTogglesContainer>
      {tabOptions.map(({ id, value, label }) => (
        <ToggleButton
          key={`${name}-${id}`}
          id={id}
          value={value}
          label={label}
          name={name}
          onChange={handleTabSelect}
          selectedValue={selectedOption}
          icon={id === 'chart' ? <ChartIcon /> : <StoryIcon />}
        />
      ))}
    </StoryChartTogglesContainer>
  );
};

export default StoryChartToggle;
