import { Paragraph } from '@atb-ventures/personas-components';
import styled from 'styled-components';
import { device } from 'theme';

export const HeroContainer = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-column-gap: 60px;
  padding-right: 30px;
  align-items: flex-start;

  @media ${device.small} {
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr min-content;
    grid-row-gap: 40px;
    padding: 0 30px;
  }

  .contentContainerPersonas {
    grid-area: 1 / 2 / 2 / 3;
    align-self: flex-end;
    @media ${device.small} {
      grid-area: 1 / 1 / 2 / 2;
    }
  }
`;

export const HeroContent = styled(Paragraph)`
  color: #6d7072;
`;

export const ImageContainer = styled.div`
  width: 100%;
  grid-area: 1 / 1 / 3 / 2;
  @media ${device.small} {
    width: 100%;
    align-self: center;
    justify-self: center;
    grid-area: 2 / 1 / 3 / 2;
  }
`;

export const ExcerptContainer = styled.div`
  grid-area: 2 / 2 / 3 / 3;
  align-self: flex-start;

  @media ${device.small} {
    grid-area: 3 / 1 / 4 / 2;
  }
`;

export const SectionContainer = styled.div`
  padding: 30px 40px;

  h3 {
    margin-bottom: 20px;
  }
`;

export const NavigationTileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  padding: 30px 40px;

  @media ${device.small} {
    flex-direction: column;
    margin-top: 50px;
  }
`;
