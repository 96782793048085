import { MetaHeading } from '@atb-ventures/personas-components';
import styled from 'styled-components';
import { device } from 'theme';

export const StoryContainer = styled.div<{ isSelected: boolean }>`
  display: ${(props) => (props.isSelected ? 'grid' : 'none')};
  margin-top: 40px;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 40px;
  .pullOutContainer {
    transform: translate(0);
  }

  @media ${device.medium} {
    grid-template-columns: 1fr;
    grid-row-gap: 30px;
  }
`;

export const BioLabelContainer = styled.div`
  margin: 32px 0;

  ${MetaHeading} {
    margin: 8px 0;
  }
`;

export const BioLabelsContainer = styled.div`
  @media ${device.medium} {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-column-gap: 30px;
    align-items: center;
  }
`;

export const StoryBioContainer = styled.div`
  @media ${device.medium} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
  }
`;
