import { MediumTitle } from '@atb-ventures/personas-components';
import { Link } from 'gatsby';
import React, { FC } from 'react';
import {
  ContentContainer,
  NavigationTileContainer,
  StyledLink,
} from './navigation-tile.style';

interface Props {
  icon?: string;
  title: string;
  link: {
    title: string;
    link: string;
  };
  backArrow?: boolean;
  className?: string;
}

export const NavigationTile: FC<Props> = ({
  icon,
  title,
  link,
  backArrow,
  className,
}) => (
  <Link to={link.link}>
    <NavigationTileContainer className={className}>
      {icon && <img src={icon} />}
      <ContentContainer>
        <MediumTitle>{title}</MediumTitle>
        <StyledLink href={link.link} backArrow={backArrow}>
          {link.title}
        </StyledLink>
      </ContentContainer>
    </NavigationTileContainer>
  </Link>
);
