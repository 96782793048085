import React, { FC } from 'react';
import {
  Title,
  Tabs,
  TabItem,
  ClusterKey,
  useChart,
  SpiderChart,
  IClusterSummaries,
  HighChartOptionsBuilder,
  ClusterSummaryData,
} from '@atb-ventures/personas-components';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import {
  DefaultMessageContainer,
  GridContainer,
  StoryChartTabs,
  WellnessFrameworkContainer,
} from './wellness-framework-display.style';
import StoryChartToggle from './story-chart-toggle/story-chart-toggle';
import Story from './story';

if (typeof Highcharts === 'object') {
  HighchartsMore(Highcharts);
}

export type scenarioKey =
  | 'current'
  | 'baseline'
  | 'growth'
  | 'decay'
  | 'equilibrium';
type categoryType =
  | 'financial'
  | 'emotional'
  | 'physical'
  | 'occupational'
  | 'social';

export interface Scenarios {
  current: {
    categories: {
      [category: string]: string[];
    };
  };
  baseline: {
    categories: {
      [category: string]: string[];
    };
  };
  growth: {
    categories: {
      [category: string]: string[];
    };
  };
  decay: {
    categories: {
      [category: string]: string[];
    };
  };
  equilibrium: {
    categories: {
      [category: string]: string[];
    };
  };
}

export type ReducedClusterSummary = Omit<ClusterSummaryData, 'visualization' | 'experiential' | 'REIClassification' | 'rational'>;

export interface ReducedClusterSummaries {
  [key: string]: ReducedClusterSummary;
}

export interface WellnessFrameworkProps {
  clusterKey: ClusterKey;
  scenarios: Scenarios;
  clusterSummaries: ReducedClusterSummaries;
  tabs: any;
}

interface ExtendedXAxisOptions {
  key: 'xAxis';
  categories: string[];
  labels: {
    distance: number;
    useHTML: boolean;
    formatter: () => void;
    reserveSpace: boolean;
    padding: number;
    y: number;
  };
}

export const WellnessFrameworkSection: FC<WellnessFrameworkProps> = ({
  clusterKey,
  scenarios,
  clusterSummaries,
  tabs: { tabOptions, selectedTab, selectedOption, handleTabSelect },
}) => {
  const START_ANGLE = 360 / 5 / 2;
  const options = new HighChartOptionsBuilder()
    .updateOption({
      key: 'xAxis',
      categories: [
        'Emotional',
        'Financial',
        'Occupational',
        'Physical',
        'Social',
      ],
      labels: {
        distance: 35,
        useHTML: true,
        formatter: () => '',
        reserveSpace: true,
        padding: 10,
        y: -2,
      },
    } as ExtendedXAxisOptions)
    .updateOption({
      key: 'pane',
      startAngle: START_ANGLE,
      size: '70%',
    } as any)
    .setSeries([
      {
        name: 'Forecast',
        color: (clusterSummaries as IClusterSummaries)[clusterKey].colors?.fill,
        marker: {
          fillColor: (clusterSummaries as IClusterSummaries)[clusterKey].colors
            ?.outline,
        },
      },
    ]).options;

  const { clusterData, chart, customLabels, selectedLabel, setChart } =
    useChart({
      Highcharts,
      clusterSummaries,
      baseOptions: options,
    });

  const isChartSelected = selectedOption === 'chart';
  const isStorySelected = selectedOption === 'story';
  const displayDefaultLabel = !selectedLabel && isChartSelected;

  return (
    <WellnessFrameworkContainer>
      <Title>Wellness Framework</Title>
      <Tabs titleContainerClassName={'scenariosTabsTitles'}>
        <TabItem title="Present Day" id="present">
          <>
            <StoryChartTabs>
              <StoryChartToggle
                tabOptions={tabOptions}
                selectedOption={selectedOption}
                handleTabSelect={handleTabSelect}
                name="story-chart-toggle"
              />
            </StoryChartTabs>
            <GridContainer>
              {isChartSelected && (
                <SpiderChart
                  Highcharts={Highcharts}
                  options={clusterData && clusterData[clusterKey]['current']}
                  setChart={setChart}
                  chart={chart}
                  customLabels={customLabels}
                  selectedLabel={selectedLabel}
                  contentLines={
                    scenarios['current'].categories[selectedLabel.toLowerCase()]
                  }
                />
              )}
              {displayDefaultLabel && (
                <DefaultMessageContainer>
                  <p>Select a category of wellness on the chart to learn more.</p>
                </DefaultMessageContainer>
              )}
            </GridContainer>
            <Story
              isSelected={isStorySelected}
              clusterData={clusterSummaries[clusterKey]}
              storyTitle="Present Day Story"
              scenarioKey="current"
            />
          </>
        </TabItem>
        <TabItem title="Baseline" id="baseline">
          <>
            <StoryChartTabs>
              <StoryChartToggle
                tabOptions={tabOptions}
                selectedOption={selectedOption}
                handleTabSelect={handleTabSelect}
                name="story-chart-toggle"
              />
            </StoryChartTabs>
            <GridContainer>
              {isChartSelected && (
                <SpiderChart
                  Highcharts={Highcharts}
                  options={clusterData && clusterData[clusterKey]['baseline']}
                  setChart={setChart}
                  chart={chart}
                  customLabels={customLabels}
                  selectedLabel={selectedLabel}
                  contentLines={
                    scenarios['baseline'].categories[selectedLabel.toLowerCase()]
                  }
                />
              )}
              {displayDefaultLabel && (
                <DefaultMessageContainer>
                  <p>Select a category of wellness on the chart to learn more.</p>
                </DefaultMessageContainer>
              )}
            </GridContainer>
            <Story
              isSelected={isStorySelected}
              clusterData={clusterSummaries[clusterKey]}
              storyTitle="Baseline Story"
              scenarioKey="baseline"
            />
          </>
        </TabItem>
        <TabItem title="Growth" id="growth">
          <>
            <StoryChartTabs>
              <StoryChartToggle
                tabOptions={tabOptions}
                selectedOption={selectedOption}
                handleTabSelect={handleTabSelect}
                name="story-chart-toggle"
              />
            </StoryChartTabs>
            <GridContainer>
              {isChartSelected && (
                <SpiderChart
                  Highcharts={Highcharts}
                  options={clusterData && clusterData[clusterKey]['growth']}
                  setChart={setChart}
                  chart={chart}
                  customLabels={customLabels}
                  selectedLabel={selectedLabel}
                  contentLines={
                    scenarios['growth'].categories[selectedLabel.toLowerCase()]
                  }
                />
              )}
              {displayDefaultLabel && (
                <DefaultMessageContainer>
                  <p>Select a category of wellness on the chart to learn more.</p>
                </DefaultMessageContainer>
              )}
            </GridContainer>
            <Story
              isSelected={isStorySelected}
              clusterData={clusterSummaries[clusterKey]}
              storyTitle="Growth Story"
              scenarioKey="growth"
            />
          </>
        </TabItem>
        <TabItem title="Decay" id="decay">
          <>
            <StoryChartTabs>
              <StoryChartToggle
                tabOptions={tabOptions}
                selectedOption={selectedOption}
                handleTabSelect={handleTabSelect}
                name="story-chart-toggle"
              />
            </StoryChartTabs>
            <GridContainer>
              {isChartSelected && (
                <SpiderChart
                  Highcharts={Highcharts}
                  options={clusterData && clusterData[clusterKey]['decay']}
                  setChart={setChart}
                  chart={chart}
                  customLabels={customLabels}
                  selectedLabel={selectedLabel}
                  contentLines={
                    scenarios['decay'].categories[selectedLabel.toLowerCase()]
                  }
                />
              )}
              {displayDefaultLabel && (
                <DefaultMessageContainer>
                  <p>Select a category of wellness on the chart to learn more.</p>
                </DefaultMessageContainer>
              )}
            </GridContainer>
            <Story
              isSelected={isStorySelected}
              clusterData={clusterSummaries[clusterKey]}
              storyTitle="Decay Story"
              scenarioKey="decay"
            />
          </>
        </TabItem>
        <TabItem title="New Equilibrium" id="equilibrium">
          <>
            <StoryChartTabs>
              <StoryChartToggle
                tabOptions={tabOptions}
                selectedOption={selectedOption}
                handleTabSelect={handleTabSelect}
                name="story-chart-toggle"
              />
            </StoryChartTabs>
            <GridContainer>
              {isChartSelected && (
                <SpiderChart
                  Highcharts={Highcharts}
                  options={clusterData && clusterData[clusterKey]['equilibrium']}
                  setChart={setChart}
                  chart={chart}
                  customLabels={customLabels}
                  selectedLabel={selectedLabel}
                  contentLines={
                    scenarios['equilibrium'].categories[
                      selectedLabel.toLowerCase()
                    ]
                  }
                />
              )}
              {displayDefaultLabel && (
                <DefaultMessageContainer>
                  <p>Select a category of wellness on the chart to learn more.</p>
                </DefaultMessageContainer>
              )}
            </GridContainer>
            <Story
              isSelected={isStorySelected}
              clusterData={clusterSummaries[clusterKey]}
              storyTitle="New Equilibrium Story"
              scenarioKey="equilibrium"
            />
          </>
        </TabItem>
      </Tabs>
    </WellnessFrameworkContainer>
  );
};

export default WellnessFrameworkSection;
