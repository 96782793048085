import styled, { css } from 'styled-components';
import { BoxContainer, Link } from '@atb-ventures/personas-components';
import Arrow from '../../../assets/images/icons/Arrow.svg';
import ForwardArrow from '../../../assets/images/icons/ForwardArrow.svg';

export const NavigationTileContainer = styled(BoxContainer)`
  display: flex;
  justify-content: space-evenly;
  &:hover {
    p {
      color: #000;
    }
  }
`;

export const ContentContainer = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
`;

export const beforeArrow = css`
  &::before {
    content: '';
    background-image: url(${Arrow});
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    left: -30px;
    top: 0px;
    width: 25px;
    height: 25px;
  }
`;

export const afterArrow = css`
  &::after {
    content: '';
    background-image: url(${ForwardArrow});
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    right: -30px;
    top: 0px;
    width: 25px;
    height: 25px;
  }
`;

export const StyledLink = styled(Link)<{ backArrow?: boolean }>`
  ${(props) =>
    props.backArrow &&
    css`
      margin-left: 30px;
      ${beforeArrow}
      &::after {
        content: none;
      }
    `}
`;
