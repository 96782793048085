import {
  Link,
  Paragraph,
  MetaHeading,
  MediumTitle,
  ClusterKey,
} from '@atb-ventures/personas-components';
import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import Img from 'gatsby-image';
import { device } from 'theme';
import {
  CopyContainer,
  FooterTileContainer,
  FooterTileCopyContainer,
  FooterTilesContainer,
  OverviewFooterContainer,
} from './overview-footer.style';
import Button from 'components/ui/button';

interface Props {
  clusterKey: ClusterKey;
}

interface GroupQueryData {
  id: string;
  title: string;
  body: string;
  cta: {
    link: string;
  }
  mobileHeadshot: any;
  headshot: any;
}

interface QueryData {
  json: {
    title: string;
    body: string;
    cta: {
      link: string;
      title: string;
    };
    groups: GroupQueryData[];
  }
}

export const OverviewFooter: FC<Props> = ({ clusterKey }) => {
  const data = useStaticQuery(graphql`
    query personasOverviewFooterQuery {
      json: personasJson(id: { eq: "personas-overview-footer" }) {
        title
        body
        cta {
          link
          title
        }
        groups {
          id
          title
          body
          cta {
            link
          }
          mobileHeadshot {
            childImageSharp {
              fixed(width: 98, height: 255, quality: 100, fit: CONTAIN) {
                ...GatsbyImageSharpFixed_withWebp
                aspectRatio
              }
            }
          }
          headshot {
            childImageSharp {
              fixed(width: 250, height: 300, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  `);

  if (!data?.json) {
    return null;
  }

  const { title, body, cta, groups } = data.json;

  return (
    <OverviewFooterContainer>
      <CopyContainer>
        <MediumTitle>{title}</MediumTitle>
        <Paragraph>{body}</Paragraph>
        <Link href={cta.link}>{cta.title}</Link>
      </CopyContainer>
      <FooterTilesContainer>
        {groups.map((group: GroupQueryData, idx: number) => {
          const isSelected = group.id === clusterKey;

          return (
            <FooterTileContainer
              key={`footer-tile-${idx}`}
              isSelected={isSelected}
            >
              <Img
                fixed={[
                  group.headshot.childImageSharp.fixed,
                  {
                    ...group.mobileHeadshot.childImageSharp.fixed,
                    media: device.small,
                  },
                  {
                    ...group.headshot.childImageSharp.fixed,
                    media: device.medium,
                  },
                  {
                    ...group.mobileHeadshot.childImageSharp.fixed,
                    media: device.xlarge,
                  },
                  {
                    ...group.headshot.childImageSharp.fixed,
                    media: device.xlargeOnly,
                  },
                ]}
              />
              <FooterTileCopyContainer>
                <MetaHeading>{group.title}</MetaHeading>
                <Paragraph>{group.body}</Paragraph>
                {!isSelected && (
                  <Button to={group.cta.link} varient="texted">
                    Learn More
                  </Button>
                )}
              </FooterTileCopyContainer>
            </FooterTileContainer>
          );
        })}
      </FooterTilesContainer>
    </OverviewFooterContainer>
  );
};

export default OverviewFooter;
