import {
  BoxContainer,
  Label,
  LargeParagraph,
  MetaHeading,
  Paragraph,
  Pullout,
} from '@atb-ventures/personas-components';
import React, { FC } from 'react';
import {
  BioLabelContainer,
  BioLabelsContainer,
  StoryBioContainer,
  StoryContainer,
} from './story.style';
import Img from 'gatsby-image';
import { ReducedClusterSummary, scenarioKey } from '../';

interface Props {
  isSelected: boolean;
  clusterData: ReducedClusterSummary;
  scenarioKey: scenarioKey;
  storyTitle: string;
}

export const Story: FC<Props> = ({
  isSelected,
  clusterData,
  scenarioKey,
  storyTitle,
}) => (
  <StoryContainer isSelected={isSelected}>
    <StoryBioContainer>
      <Img
        fluid={
          clusterData.scenarios[scenarioKey].story.headshot.childImageSharp
            .fluid
        }
      />
      <BioLabelsContainer>
        <BioLabelContainer>
          <MetaHeading>Name</MetaHeading>
          <Label>{clusterData.personaName}</Label>
        </BioLabelContainer>
        <BioLabelContainer>
          <MetaHeading>Age</MetaHeading>
          <Label>{clusterData.age}</Label>
        </BioLabelContainer>
        <BioLabelContainer>
          <MetaHeading>Gender</MetaHeading>
          <Label>{clusterData.gender}</Label>
        </BioLabelContainer>
        <BioLabelContainer>
          <MetaHeading>Income Level</MetaHeading>
          <Label>{clusterData.incomeLevel}</Label>
        </BioLabelContainer>
      </BioLabelsContainer>
    </StoryBioContainer>
    <BoxContainer>
      <MetaHeading>{storyTitle}</MetaHeading>
      <LargeParagraph>
        {clusterData.scenarios[scenarioKey].story.intro}
      </LargeParagraph>
      {clusterData.scenarios[scenarioKey].story.paragraphGroup1.map(
        (paragraph: string, idx: number) => (
          <Paragraph key={`group-1-${idx}`}>{paragraph}</Paragraph>
        ),
      )}
      <Pullout
        body={clusterData.scenarios[scenarioKey].story.pullout}
        className="pullOutContainer"
      />
      {clusterData.scenarios[scenarioKey].story.paragraphGroup2.map(
        (paragraph: string, idx: number) => (
          <Paragraph key={`group-2-${idx}`}>{paragraph}</Paragraph>
        ),
      )}
    </BoxContainer>
  </StoryContainer>
);

export default Story;
