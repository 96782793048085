import styled from 'styled-components';
import { device } from 'theme';

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;

  span {
    transition: none;
  }

  @media ${device.medium} {
    grid-template-columns: 1fr;
  }
`;

export const WellnessFrameworkContainer = styled.div`
  .scenariosTabsTitles {
    display: flex;
  }
`;

export const StoryChartTabs = styled.div``;

export const DefaultMessageContainer = styled.div`
  padding: 24px;
  border: 1px solid #e2e2e3;
  border-radius: 12px;
  font-family: Ubuntu;
`;
