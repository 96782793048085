import { MetaHeading, Paragraph } from '@atb-ventures/personas-components';
import styled from 'styled-components';
import { device } from 'theme';

export const OverviewFooterContainer = styled.section`
  background: linear-gradient(180deg, #f8f8f8 0%, #ffffff 100%);
  display: flex;
  flex-direction: column;
  padding: 96px 40px;

  @media ${device.medium} {
    padding: 56px 24px;
  }
`;

export const CopyContainer = styled.div`
  max-width: 600px;
`;

export const FooterTilesContainer = styled.div`
  display: flex;
  margin: 50px 0;
  @media ${device.medium} {
    flex-direction: column;
  }
`;

export const FooterTileContainer = styled.div<{ isSelected: boolean }>`
  background: #ffffff;
  box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  margin: 16px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 20px;
  align-items: center;
  .gatsby-image-wrapper {
    border-bottom-left-radius: 12px;
    border-top-left-radius: 12px;
    filter: ${(props) =>
    props.isSelected ? 'grayscale(0)' : 'grayscale(100%)'};
  }

  &:hover {
    .gatsby-image-wrapper {
      filter: grayscale(0);
    }
  }
`;

export const FooterTileCopyContainer = styled.div`
  ${MetaHeading} {
    color: #000000;
    margin-bottom: 16px;
  }
  ${Paragraph} {
    font-size: 14px;
  }
  padding-right: 16px;
`;
