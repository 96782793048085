import React from 'react';
import { graphql } from 'gatsby';
import SEO from 'components/seo';
import Layout from 'containers/layout/layout';
import Header from 'containers/layout/header';
import Footer from 'containers/layout/footer';
import Img from 'gatsby-image';
import { ClusterKey, IdentitySection, IdentitySectionProps, Link } from '@atb-ventures/personas-components';
import {
  ExcerptContainer,
  HeroContainer,
  HeroContent,
  ImageContainer,
  NavigationTileContainer,
  SectionContainer,
} from './personas-group.style';
import WellnessFrameworkSection from 'containers/personas/wellness-framework-display';
import { HeroCallout } from 'containers/personas/hero-callout';
import SubpageHeader from 'containers/personas/subpage-header';
import { PersonasNavigationTile } from 'containers/personas/navigation-tile/personas-navigation-tile';
import OverviewFooter from 'containers/personas/overview-footer';
import { Tab, useTabs } from 'utils/useTabs';
import { FC } from 'react';

interface LinkProps {
  title: string;
  link: string;
}

interface TabItems {
  label: string;
  listItems: string[];
}

interface ScenarioItem { 
  data: string[];
  categories: {
    emotional: string[];
    financial: string[];
    occupational: string[];
    physical: string[];
    social: string[];
  }
  story: {
    headshot: any;
    intro: string;
    paragraphGroup1: string[];
    pullout: string;
    paragraphGroup2: string[];
  }
}

export interface Group {
  id: string;
  age: number;
  gender: string;
  incomeLevel: string;
  name: string;
  personaName: string;
  groupNumber: number;
  navigation: {
    previous: LinkProps;
    next: LinkProps;
  }
  headshot: any;
  intro: {
    metaHeading: string;
    description: string;
    excerpt: string;
  };
  colors: {
    outline: string;
    fill: string;
  }
  identity: IdentitySectionProps;
  scenarios: {
    baseline: ScenarioItem;
    current: ScenarioItem;
    decay: ScenarioItem;
    equilibrium: ScenarioItem;
    growth: ScenarioItem;  
  }
}

interface PersonasGroupTemplateProps {
  data: {
    json: {
      groups: Group[];
    }
  };
  location: string;
  pageContext: {
    id: string;
  };
}

const PersonasGroupTemplate: FC<PersonasGroupTemplateProps> = ({ data, pageContext, location }) => {
  const groupData: Group = data.json.groups.find((group) => group.id === pageContext.id) as Group;

  const { tabOptions, selectedTab, selectedOption, handleTabSelect } = useTabs({
    tabs: [
      {
        id: 'chart',
        name: 'Chart',
      },
      {
        id: 'story',
        name: 'Story',
      },
    ] as Tab[],
  });

  return (
    <Layout location={location}>
      <SEO title={`Persona: ${pageContext.id}`} />
      <Header fixedDisplayVarient={true} />
      <main className="site-wrapper-reveal">
        <SubpageHeader
          title="Humans of 2030+"
          breadcrumbs={[
            {
              path: '/humans-of-2030',
              title: 'Overview',
            },
            {
              path: '/humans-of-2030/groups',
              title: 'The People',
            },
            {
              path: `/humans-of-2030/groups/${pageContext.id}`,
              title: `Group ${groupData.groupNumber}: ${groupData.name}`,
            },
          ]}
        />
        <HeroContainer>
          <ImageContainer>
            <Img fluid={groupData.headshot.childImageSharp.fluid} />
          </ImageContainer>
          <HeroCallout
            metaHeading={groupData.intro.metaHeading}
            title={groupData.name}
            body={groupData.intro.description}
            className="contentContainerPersonas"
          />
          <ExcerptContainer>
            <HeroContent>{groupData.intro.excerpt}</HeroContent>
            <Link
              href="#story-section"
              onClick={(e) => {
                handleTabSelect({ target: { value: 'story' } } as any);
              }}
            >
              Read {groupData.personaName}&apos;s story
            </Link>
          </ExcerptContainer>
        </HeroContainer>
        <SectionContainer>
          <IdentitySection {...groupData.identity} />
        </SectionContainer>
        <div id="story-section"></div>
        <SectionContainer>
          <WellnessFrameworkSection
            scenarios={groupData.scenarios}
            clusterSummaries={{
              [groupData.id]: {
                ...groupData,
              },
            }}
            clusterKey={groupData.id as ClusterKey}
            tabs={{
              tabOptions,
              selectedTab,
              selectedOption,
              handleTabSelect,
            }}
          />
        </SectionContainer>
        <NavigationTileContainer>
          <PersonasNavigationTile
            title={groupData.navigation.previous.title}
            link={{
              link: groupData.navigation.previous.link,
              title: 'Explore',
            }}
            backArrow={true}
          />
          {groupData.navigation.next && (
            <PersonasNavigationTile
              title={groupData.navigation.next?.title}
              link={{
                link: groupData.navigation.next?.link,
                title: 'Explore',
              }}
            />
          )}
        </NavigationTileContainer>
        <OverviewFooter clusterKey={pageContext.id as ClusterKey} />
      </main>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query PersonaByPersonaIdQuery {
    json: personasJson(id: { eq: "group-data" }) {
      id
      groups {
        age
        gender
        id
        incomeLevel
        name
        personaName
        groupNumber
        navigation {
          previous {
            title
            link
          }
          next {
            title
            link
          }
        }
        headshot {
          childImageSharp {
            fluid(maxWidth: 650, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
              presentationHeight
            }
          }
        }
        intro {
          metaHeading
          description
          excerpt
        }
        colors {
          outline
          fill
        }
        identity {
          externalItems {
            label
            listItems
          }
          internalItems {
            label
            listItems
          }
        }
        scenarios {
          baseline {
            data
            categories {
              emotional
              financial
              occupational
              physical
              social
            }
            story {
              headshot {
                childImageSharp {
                  fluid(maxWidth: 350, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                    presentationHeight
                  }
                }
              }
              intro
              paragraphGroup1
              pullout
              paragraphGroup2
            }
          }
          current {
            data
            categories {
              emotional
              financial
              occupational
              physical
              social
            }
            story {
              headshot {
                childImageSharp {
                  fluid(maxWidth: 350, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                    presentationHeight
                  }
                }
              }
              intro
              paragraphGroup1
              pullout
              paragraphGroup2
            }
          }
          decay {
            data
            categories {
              emotional
              financial
              occupational
              physical
              social
            }
            story {
              headshot {
                childImageSharp {
                  fluid(maxWidth: 350, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                    presentationHeight
                  }
                }
              }
              intro
              paragraphGroup1
              pullout
              paragraphGroup2
            }
          }
          equilibrium {
            data
            categories {
              emotional
              financial
              occupational
              physical
              social
            }
            story {
              headshot {
                childImageSharp {
                  fluid(maxWidth: 350, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                    presentationHeight
                  }
                }
              }
              intro
              paragraphGroup1
              pullout
              paragraphGroup2
            }
          }
          growth {
            data
            categories {
              emotional
              financial
              occupational
              physical
              social
            }
            story {
              headshot {
                childImageSharp {
                  fluid(maxWidth: 350, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    presentationWidth
                    presentationHeight
                  }
                }
              }
              intro
              paragraphGroup1
              pullout
              paragraphGroup2
            }
          }
        }
      }
    }
  }
`;

export default PersonasGroupTemplate;
